import React, {useState} from "react"
import {Alert, Button, Col, Container, Form, FormGroup, Input, Row} from 'reactstrap'
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown";

import Layout from "../components/layout"
import PageTitle from '../components/pageTitle'
import SEO from "../components/seo"
import {LangContext} from "../components/kbusWrapper";

const FormRowClassName = "px-2 px-lg-5";

const ObjetosPerdidosPage = ({ data }) => {

  const [formStatus, setFormStatus] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [selectedLinea, setSelectedLinea] = useState(null);
  const [selectedRuta, setSelectedRuta] = useState(null);

  const submitForm = (ev) => {
    ev.preventDefault();
    setProcessing(true);
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return;
      } else {
        setProcessing(false)
      }
      if (xhr.status === 200) {
        form.reset();
        setFormStatus('ok');
      } else {
        setFormStatus('ko');
      }
    };
    xhr.send(data);
  }

  const selectLineaByName = (nombreLinea) => {
    const lineasFiltered = data.lineas.edges.filter((edge) => edge.node.nombre === nombreLinea)
    lineasFiltered.length > 0 && setSelectedLinea(lineasFiltered[0].node)
  }

  const selectRutaByName = (nombreRuta) => {
    const rutasFiltered = selectedLinea.rutas.filter((ruta) => ruta.nombre === nombreRuta)
    rutasFiltered.length > 0 && setSelectedRuta(rutasFiltered[0])
  }

  return (
    <LangContext.Consumer>
      { context => (
        <Layout>
          <SEO title={context.translate(data, 'objetosPerdidos', 'titulo')} />
          <Container className="py-5 mb-5">
            <PageTitle title={context.translate(data, 'objetosPerdidos', 'titulo')}/>

            <Form className="px-2 px-lg-5 pt-5"
                  onSubmit={submitForm}
                  action={context.translate(data, 'objetosPerdidos', 'formulario').action}
                  method="POST">
              <Input type="hidden" name="tipo" id="tipo" value={context.translate(data, 'objetosPerdidos', 'formulario').tipo} required/>

              <Row form className={FormRowClassName}>
                <Col xs={12} lg={6}>
                  <FormGroup>
                    <Input type="text" name="nombre" id="nombre" placeholder={context.translate(data, 'objetosPerdidos', 'formulario').nombre} required/>
                  </FormGroup>
                </Col>
                <Col xs={12} lg={6}>
                  <FormGroup>
                    <Input type="text" name="apellidos" id="apellidos" placeholder={context.translate(data, 'objetosPerdidos', 'formulario').apellidos} required/>
                  </FormGroup>
                </Col>
              </Row>

              <Row form className={FormRowClassName}>
                <Col xs={12} lg={6}>
                  <FormGroup>
                    <Input type="email" name="email" id="email" placeholder={context.translate(data, 'objetosPerdidos', 'formulario').email} required/>
                  </FormGroup>
                </Col>
                <Col xs={12} lg={6}>
                  <FormGroup>
                    <Input type="text" name="telefono" id="telefono" placeholder={context.translate(data, 'objetosPerdidos', 'formulario').telefono} required/>
                  </FormGroup>
                </Col>
              </Row>

              <Row form className={FormRowClassName}>
                <Col xs={12} lg={9}>
                  <FormGroup>
                    <Input type="text" name="direccion" id="direccion" placeholder={context.translate(data, 'objetosPerdidos', 'formulario').direccion} required/>
                  </FormGroup>
                </Col>
                <Col xs={12} lg={3}>
                  <FormGroup>
                    <Input type="text" name="cp" id="cp" placeholder={context.translate(data, 'objetosPerdidos', 'formulario').cp} required/>
                  </FormGroup>
                </Col>
              </Row>

              <Row form className={FormRowClassName}>
                <Col xs={12} lg={6}>
                  <FormGroup>
                    <Input type="text" name="municipio" id="municipio" placeholder={context.translate(data, 'objetosPerdidos', 'formulario').municipio} required/>
                  </FormGroup>
                </Col>
                <Col xs={12} lg={6}>
                  <FormGroup>
                    <Input type="text" name="pais" id="pais" placeholder={context.translate(data, 'objetosPerdidos', 'formulario').pais} required/>
                  </FormGroup>
                </Col>
              </Row>

              <Row form className={FormRowClassName}>
                <Col xs={12} lg={6}>
                  <FormGroup>
                    <Input type="date" name="fecha" id="fecha" placeholder={context.translate(data, 'objetosPerdidos', 'formulario').fecha} required/>
                  </FormGroup>
                </Col>
                <Col xs={12} lg={6}>
                  <FormGroup>
                    <Input type="time" name="hora" id="hora" placeholder={context.translate(data, 'objetosPerdidos', 'formulario').hora} required/>
                  </FormGroup>
                </Col>
              </Row>

              <Row form className={FormRowClassName}>
                <Col xs={12} lg={4}>
                  <FormGroup>
                    <Input className="text-capitalize" type="select" name="linea" id="linea" placeholder={"Línea"} onChange={(e) => {selectLineaByName(e.target.value)}}>
                      <option value={null}>Línea</option>
                      {data.lineas.edges.map((edge) => (
                        <option key={edge.node.label} value={edge.node.nombre} className="text-capitalize">{edge.node.nombre.toLowerCase()}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={12} lg={4}>
                  <FormGroup>
                    <Input className="text-capitalize" type="select" name="sentido" id="sentido" placeholder={"Sentido"} onChange={(e) => {selectRutaByName(e.target.value)}}>
                      <option value={null}>Sentido</option>
                      {selectedLinea && selectedLinea.rutas.map((ruta) => (
                        <option key={ruta.label} value={ruta.nombre} className="text-capitalize">{ruta.nombre.toLowerCase()}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={12} lg={4}>
                  <FormGroup>
                    <Input className="text-capitalize" type="select" name="parada" id="parada" placeholder={"Parada"} onChange={(e) => {console.log(e)}}>
                      <option value={null}>Parada</option>
                      {selectedRuta && selectedRuta.paradas.map((parada) => (
                        <option key={parada.label} value={parada.nombre} className="text-capitalize">{parada.nombre.toLowerCase()}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              <Row form className={FormRowClassName}>
                <Col xs={12}>
                  <FormGroup>
                    <Input type="textarea" name="mensaje" id="mensaje" placeholder={context.translate(data, 'objetosPerdidos', 'formulario').mensaje} rows={8} required/>
                  </FormGroup>
                </Col>
              </Row>

              <Row form className={`${FormRowClassName} pt-4`}>
                <Col xs={12}>
                  <FormGroup className="text-center">
                    {formStatus === null ? (
                        <Button color="success" className="px-5" disabled={processing}>
                          {!processing ? context.translate(data, 'objetosPerdidos', 'formulario').enviar : context.translate(data, 'objetosPerdidos', 'formulario').enviando}
                        </Button>) :
                      formStatus === 'ok' ?
                        (<Alert color="success">{context.translate(data, 'objetosPerdidos', 'formulario').mensajeOk}</Alert>) :
                        (<Alert color="danger">{context.translate(data, 'objetosPerdidos', 'formulario').mensajeKo}</Alert>) }
                  </FormGroup>
                </Col>
              </Row>

            </Form>

            <ReactMarkdown source={context.translate(data, 'objetosPerdidos', 'texto')} className="text-muted pt-4 px-xl-5 mx-xl-5 text-center text-md-left"/>
          </Container>
        </Layout>
      )}
    </LangContext.Consumer>
  )
}

export default ObjetosPerdidosPage

export const pageQuery = graphql`  
  query {
    objetosPerdidos: strapiObjetosPerdidos {
      titulo
      texto
      formulario {
        action
        apellidos
        cp
        direccion
        email
        enviando
        enviar
        fecha
        hora
        mensaje
        mensajeKo
        mensajeOk
        municipio
        nombre
        pais
        telefono
        tipo
      }
    }
    objetosPerdidos_eus: strapiObjetosPerdidosEus {
      titulo
      texto
      formulario {
        action
        apellidos
        cp
        direccion
        email
        enviando
        enviar
        fecha
        hora
        mensaje
        mensajeKo
        mensajeOk
        municipio
        nombre
        pais
        telefono
        tipo
      }
    }
    lineas: allStrapiLineas {
      edges {
        node {
          label
          nombre
          rutas {
            direccion
            nombre
            paradas {
              nombre
              label
            }
          }
        }
      }
    }
  }
`